import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { graphql, Link, StaticQuery } from "gatsby";
import { colorPalette } from "../colors";
import { GatsbyImage } from "gatsby-plugin-image";
import teamImage from "../../images/team.png";

const styles = (theme) => ({
  root: {
    gridColumn: "1/-1",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "1.5vw",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    marginBottom: "calc( 2vh + 2vw)",
  },
  paper: {
    backgroundImage: `url(${teamImage})`,
    backgroundSize: "cover",
    position: "relative",
    boxShadow: `inset 0 0 0 2000px ${colorPalette.blueWithAlpha("AA")}`,
    padding: "10px",

    "&:hover": {
      padding: 0,
      boxShadow: "none",

      "& $paperSub": {
        background: colorPalette.orangeWithAlpha("AA"),
        padding: "10px",
      },

      "& $img": {
        filter: "brightness(0) invert(1)",
      },
    },
  },

  paperSub: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.9)",
  },

  img: {
    height: "150px",
    width: "100%",
    objectFit: "contain",
  },
});

const Departments = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query {
        allDepartments(sort: { fields: [url] }) {
          nodes {
            id
            name
            url
          }
        }
        allFile(filter: { relativeDirectory: { eq: "services" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <section className={classes.root} id="roller">
          {data.allDepartments.nodes.map((department) => {
            const image = data.allFile.edges.find((edge) => edge.node.name === department.url);
            return <Department department={department} key={department.url} classes={classes} imageNode={image.node} />;
          })}
        </section>
      );
    }}
  />
);

const Department = ({ classes, department, imageNode }) => {
  console.log(imageNode.childImageSharp.gatsbyImageData);
  return (
    <a href={`/roller/${department.url}/`}>
      <div className={classes.paper}>
        <div className={classes.paperSub}>
          <GatsbyImage
            image={imageNode.childImageSharp.gatsbyImageData}
            className={classes.img}
            alt="Department logo"
          />
        </div>
      </div>
    </a>
  );
};

export default withStyles(styles)(Departments);
