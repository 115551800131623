import React from "react";
import { withStyles } from "@material-ui/core";
import Departments from "../components/home/Departments";
import SubPageBase from "../components/SubPageBase";
import BoxTitle from "../components/BoxTitle";
import rollerImg from "../images/roller.png";
import ArrowButton from "../util/ArrowButton";

const styles = (theme) => ({
  content: {
    width: "80%",
    margin: "auto",
    display: "grid",
    gridGap: "1.5vw",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
});

const RollerPage = ({ classes }) => {
  return (
    <SubPageBase title="ROLLERİMİZ" img={rollerImg}>
      <div className={classes.content}>
        <BoxTitle title="Potansiyelimizi çeşitli rollerde, lokasyonlarda, takımlarda ortaya çıkarırız." />
        <ArrowButton to="/ilanlar/" text="Açık Pozisyonlar" spacing={1} />
        <Departments />
      </div>
    </SubPageBase>
  );
};

export default withStyles(styles)(RollerPage);
